import Splide from "@splidejs/splide";

// HOMEPAGE
if (document.querySelector(".splide.header")) {
  // Homepage header slider
  new Splide(".splide.header", {
    type: "loop",
    autoplay: true,
    interval: 5000,
    pagination: true,
    gap: 10,
    arrows: false,
  }).mount();

  // Homepage offer slider
  new Splide(".splide.offer", {
    type: "loop",
    autoplay: true,
    interval: 5000,
    pagination: false,
    gap: 20,
    mediaQuery: "min",
    arrows: false,

    breakpoints: {
      1200: {
        perPage: 3,
        perMove: 1,
        isNavigation: false,
        drag: false,
        arrows: false,
        autoplay: false,
      },
    },
  }).mount();
}

// OTHERS

// last posts slider
if (document.querySelector(".splide.last-posts")) {
  new Splide(".splide.last-posts", {
    type: "loop",
    autoplay: true,
    interval: 5000,
    pagination: false,
    perPage: 2,
    perMove: 1,
    gap: 15,
    breakpoints: {
      1400: {
        perPage: 1,
      },
    },
  }).mount();
}

if (document.querySelector(".splide.widget-offer")) {
  new Splide(".splide.offer", {
    type: "loop",
    autoplay: true,
    interval: 5000,
    pagination: false,
    gap: 20,
    mediaQuery: "min",

    breakpoints: {
      1200: {
        perPage: 3,
        perMove: 1,
        isNavigation: false,
        drag: false,
        arrows: false,
        autoplay: false,
      },
    },
  }).mount();
}